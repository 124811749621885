import { useContext, useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import EditableInput from '../../components/editable-input/editable-input.component'
import { Server } from '../../components/server/component'
import styles from './screen.module.css'

const SimulatorScreen = () => {
  const { _drones } = useContext(Server)
  const [drones, setDrones] = useState([])
  const [selected, setSelected] = useState()
  useEffect(() => {
    _drones.load(setDrones)
  }, [_drones])

  return (
    <div className={styles.container}>
      <div className={styles.droneList}>
        <Button onClick={() => _drones.load(setDrones)}>Aktualisieren</Button>
        {drones.map((d) => (
          <div onClick={() => setSelected(d)} key={d.position.id}>
            {d.position.id}
          </div>
        ))}
      </div>
      <div className={styles.panel}>
        {selected && (
          <>
            <div>
              <span>ID</span>
              <span>{selected.position.id}</span>
            </div>
            <div>
              <span>Longitude</span>
              <span>
                <EditableInput
                  placeholder='Longitude'
                  value={selected.position.long}
                  onChange={(newValue) => {
                    setSelected({ ...selected, position: { ...selected.position, long: newValue === null ? null : parseFloat(newValue) } })
                  }}
                />
              </span>
            </div>
            <div>
              <span>Latitude</span>
              <span>
                <EditableInput
                  placeholder='Longitude'
                  value={selected.position.lat}
                  onChange={(newValue) => {
                    setSelected({ ...selected, position: { ...selected.position, lat: newValue === null ? null : parseFloat(newValue) } })
                  }}
                />
              </span>
            </div>
            <div>
              <span>Höhe</span>
              <span>
                <EditableInput
                  placeholder='Longitude'
                  value={selected.position.height}
                  onChange={(newValue) => {
                    setSelected({ ...selected, position: { ...selected.position, height: newValue === null ? null : parseFloat(newValue) } })
                  }}
                />
              </span>
            </div>
            <div>
              <span>Longitude Delta</span>
              <span>
                <EditableInput
                  placeholder='Longitude'
                  value={selected.dlong}
                  onChange={(newValue) => {
                    setSelected({ ...selected, dlong: newValue === null ? null : parseFloat(newValue) })
                  }}
                />
              </span>
            </div>
            <div>
              <span>Latitude Delta</span>
              <span>
                <EditableInput
                  placeholder='Latitude'
                  value={selected.dlat}
                  onChange={(newValue) => {
                    setSelected({ ...selected, dlat: newValue === null ? null : parseFloat(newValue) })
                  }}
                />
              </span>
            </div>
            <div>
              <Button
                onClick={() =>
                  _drones.update(selected.position.id, selected, () =>
                    _drones.load((data) => {
                      setDrones(data)
                      setSelected(data.find((d) => d.position.id === selected.position.id))
                    })
                  )
                }
              >
                Senden
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default SimulatorScreen
