import { useEffect, useRef, useState } from 'react'
import EditableLabel from '../editable-label/editable-label.component'

const EditableInput = ({ value, placeholder, onChange }) => {
  const inputRef = useRef()
  const [currentValue, setCurrentValue] = useState()

  useEffect(() => {
    setCurrentValue(value === null ? '' : value)
  }, [value])

  return (
    <EditableLabel
      text={currentValue}
      placeholder={placeholder}
      type='input'
      childRef={inputRef}
      onReset={() => setCurrentValue(value === null ? '' : value)}
      onAccept={() => {
        if (value !== currentValue) {
          console.log('call onChange', onChange, currentValue)
          onChange?.(currentValue === '' ? null : currentValue)
        }
      }}
    >
      <input
        type='text'
        placeholder={placeholder}
        value={currentValue}
        onChange={(e) => {
          setCurrentValue(e.target.value)
        }}
        ref={inputRef}
      />
    </EditableLabel>
  )
}

export default EditableInput
