import React from 'react'
import { Link } from 'react-router-dom'

import styles from './component.module.css'

const PageHeader = () => {
  const a = (
    <>
      <Link to='/'>
        <div>Radar</div>
      </Link>
      <Link to='/simulator'>
        <div>Simulator</div>
      </Link>
    </>
  )

  return (
    <div className={styles.pageHeader}>
      <div className={styles.menu}>
        <Link to='/'>
          <div className={styles.home}>
            Jedsy
          </div>
        </Link>
        {a}
      </div>
    </div>
  )
}

export default PageHeader
