/** @format */

import React, { memo, useState } from 'react'
import { Viewer, Entity, Camera, ImageryLayer, CustomDataSource, BillboardGraphics } from 'resium'
import { Cartesian3, Ion, createWorldTerrain, ArcGisMapServerImageryProvider, CallbackProperty, IonResource, PathGraphics, Transforms, Quaternion } from 'cesium'
import styles from './screen.module.css'
import { useSubscription } from 'react-stomp-hooks'
import DroneList from '../../components/drone-list/component'

Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI4YTIxOWYxNy0zMTA4LTQwNWQtYTljZC0wZGNmYjJiMDAzNDMiLCJpZCI6MTA1MzQ2LCJpYXQiOjE2NjA5ODY3MzN9.0s2az3z_SNwDXcnlhzhP1SxqqzgQzHFNZrxPbIFKoL8'

// Open Street Map Server
const imageryProvider = new ArcGisMapServerImageryProvider({
  url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer',
})

let viewer = null

const known = new Set()

const myDrones = {}

const DRONE_ASSET_ID = 1294865 // 1290041 // 1273784;

const addEntity = async (droneEvent) => {
  if (droneEvent.entity && viewer.entities.contains(droneEvent.entity)) {
    return
  } else if (droneEvent.entity) {
    viewer.entities.add(droneEvent.entity)
    return
  }

  droneEvent.entity = viewer.entities.add({
    name: droneEvent.id,
    id: droneEvent.id,
    position: new CallbackProperty(() => Cartesian3.fromDegrees(droneEvent.long, droneEvent.lat, droneEvent.height), false),
    model: { uri: await IonResource.fromAssetId(DRONE_ASSET_ID), scale: 1, minimumPixelSize: 40 },
    orientation: new CallbackProperty(() => {
      const direction = Cartesian3.subtract(droneEvent.lastPosition, droneEvent.position, new Cartesian3())
      // console.log('direction', direction)
      if (Cartesian3.magnitude(direction) === 0) {
        return droneEvent.lastOrientation || Quaternion.IDENTITY
      }
      const norm = direction // Cartesian3.normalize(direction, new Cartesian3())
      // console.log('norm', norm)
      const rotationMatrix = Transforms.rotationMatrixFromPositionVelocity(Cartesian3.fromDegrees(droneEvent.long, droneEvent.lat, droneEvent.height), norm)
      // const rot90 = Matrix3.fromRotationZ(Math.toRadians(-10))
      // Matrix3.multiply(rotationMatrix, rot90, rotationMatrix)
      const orientation = Quaternion.fromRotationMatrix(rotationMatrix)
      droneEvent.lastOrientation = orientation
      return orientation
    }, false),
    description: droneEvent.id,
    path: new PathGraphics({ width: 3 }),
  })
}

const processEvent = async (droneEvent) => {
  if (!viewer) {
    console.log('skipping processEvent as viewer is null')
    return
  }
  if (known.has(droneEvent.id)) {
    const d = myDrones[droneEvent.id]
    if (d) {
      if (droneEvent.long !== d.position.long || droneEvent.lat !== d.position.lat || droneEvent.height !== d.position.height) {
        d.lastPosition = d.position
      }
      d.long = droneEvent.long
      d.lat = droneEvent.lat
      d.height = droneEvent.height
      d.position = Cartesian3.fromDegrees(d.long, d.lat, d.height)
      try {
        await addEntity(d)
      } catch (e) {
        console.log('re-add failed')
      }
      return d
    }
  } else {
    known.add(droneEvent.id)
    // console.log('new drone', droneEvent.id)
    droneEvent.direction = Cartesian3.UNIT_X
    droneEvent.position = Cartesian3.fromDegrees(droneEvent.long, droneEvent.lat, droneEvent.height)
    droneEvent.lastPosition = droneEvent.position
    console.log('add entity')
    addEntity(droneEvent)
    // console.log('new drone', droneEvent.id, droneEvent.entity)
    myDrones[droneEvent.id] = droneEvent
    return droneEvent
  }
}

const ViewerComponent = memo(() => {
  console.log('rendering the viewer')
  return (
    <Viewer
      ref={(e) => {
        console.log('ref', e)
        viewer = e ? e.cesiumElement : undefined
      }}
      className={styles.viewer}
      terrainProvider={createWorldTerrain()}
    >
      <ImageryLayer imageryProvider={imageryProvider} />
      <Camera position={Cartesian3.fromDegrees(7.169266252827092, 50.81271429380591, 370)} up={Cartesian3.fromDegrees(7.169266252827092, 50.81271429380591, 370)} />
      <CustomDataSource>
        <Entity name='Logo' position={Cartesian3.fromDegrees(7.1692662528, 50.812714293, 150)}>
          <BillboardGraphics image='./Andreas-Icon-512.png' scale={0.1} />
        </Entity>
      </CustomDataSource>
    </Viewer>
  )
})

const RadarScreen = () => {
  const [drones, setDrones] = useState({})
  useSubscription('/topics/position', (message) => {
    const msg = JSON.parse(message.body)
    processEvent(msg).then((event) => {
      let drone = drones[msg.id]
      if (drone) {
        drone.long = event.long
        drone.lat = event.lat
        drone.height = event.height
        drones[event.id] = drone
        setDrones({ ...drones })
      } else if (event) {
        drones[event.id] = event
        setDrones({ ...drones })
      }
    })
  })
  return (
    <div className={styles.layout}>
      <DroneList drones={drones} viewer={viewer} />
      <ViewerComponent />
    </div>
  )
}
export default RadarScreen
