import { useState } from 'react'
import { Button } from 'react-bootstrap'
import styles from './component.module.css'

const DroneList = ({ drones, viewer }) => {
  const [tracked, setTracked] = useState()
  return (
    <div className={styles.droneSelect}>
      {Object.keys(drones || {})
        .map((k) => drones[k])
        .map?.((d) => (
          <div
            key={d.id}
            onClick={() => {
              viewer.trackedEntity = d.entity
              setTracked(d)
            }}
          >
            {d.id} ({d.long?.toFixed(8)}, {d.lat?.toFixed(8)})
          </div>
        ))}
      <hr />
      <Button
        disabled={!tracked}
        onClick={() => {
          viewer.trackedEntity = null
          setTracked(null)
        }}
      >
        Untrack
      </Button>
      {tracked && (
        <>
          <div>Tracked: {tracked?.id}</div>
          <div>
            Current Position: {tracked.long.toFixed(8)}, {tracked.lat.toFixed(8)}, {tracked.height}
          </div>
        </>
      )}
    </div>
  )
}
export default DroneList
