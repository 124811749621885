/** @format */

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import PageHeader from './components/page-header/component'

import { StompSessionProvider } from 'react-stomp-hooks'
import SimulatorScreen from './screens/simulator/screen'
import RadarScreen from './screens/radar/screen'
import ServerProvider, { BASE_URLS } from './components/server/component'

const App = () => {
  return (
    //Initialize Stomp connection, will use SockJS for http(s) and WebSocket for ws(s)
    //The Connection can be used by all child components via the hooks or hocs.
    <>
      <StompSessionProvider
        url={BASE_URLS.radar}
        //All options supported by @stomp/stompjs can be used here
      >
        <ServerProvider>
          <Router>
            <PageHeader />
            <Routes>
              <Route path='/' element={<RadarScreen />} />
              <Route path='/simulator' element={<SimulatorScreen />} />
            </Routes>
          </Router>
        </ServerProvider>
      </StompSessionProvider>
    </>
  )
}

export default App
