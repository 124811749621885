import { createContext } from 'react'
import { trackPromise } from 'react-promise-tracker'
import { notify } from 'react-notify-toast'

export const Server = createContext()

export const BASE_URLS = {
  radar: process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://jedsy.tk/radar',
  faker: process.env.REACT_APP_FAKER_URL ? process.env.REACT_APP_FAKER_URL : 'https://jedsy.tk/simulator',
}

function executeFetch(url, requestOptions, callback, errorCallback) {
  trackPromise(
    fetch(url, requestOptions)
      .then((response) => {
        console.log('received', response)
        if (response.status === 200) {
          console.log('status 200')
          return response.json()
        }
        if (response.status === 204) {
          console.log('status 204')
          return {}
        }
        if (response.headers.get('Content-Type') === 'application/json') {
          console.log('error with json')
          return response.json().then((error) => Promise.reject(error))
        }
        console.log('error without json')
        return response.text().then((text) => Promise.reject({ message: text }))
      })
      .then((result) => callback(result))
      .catch((error) => {
        if (error.toString().includes('NetworkError') || error.message.includes('Failed to fetch')) {
          notify.show('Der Server ist nicht erreichbar. Bitte versuchen Sie es später erneut.', 'error', 10000)
        } else if (errorCallback) {
          errorCallback(error)
        }
      })
  )
}

function executeGet(url, query, callback, errorCallback) {
  var myHeaders = new Headers()
  myHeaders.append('Accept', 'application/json')
  // myHeaders.append('Authorization', 'Bearer ' + token)

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  const _url = new URL(BASE_URLS.faker + url)
  if (query) {
    _url.search = new URLSearchParams(query)
  }
  executeFetch(_url, requestOptions, callback, errorCallback)
}

function executePost(url, query, body, callback, errorCallback) {
  var myHeaders = new Headers()
  myHeaders.append('Accept', 'application/json')
  // myHeaders.append('Authorization', 'Bearer ' + token)

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
  }
  if (body) {
    myHeaders.append('Content-Type', 'application/json')
    requestOptions.body = JSON.stringify(body)
  }
  console.log('executePost ' + url, requestOptions.body)
  const _url = new URL(BASE_URLS.faker + url)
  if (query) {
    _url.search = new URLSearchParams(query)
  }
  executeFetch(_url, requestOptions, callback, errorCallback)
}

function executePut(url, body, callback, errorCallback) {
  var myHeaders = new Headers()
  myHeaders.append('Accept', 'application/json')
  myHeaders.append('Content-Type', 'application/json')
  // myHeaders.append('Authorization', 'Bearer ' + token)
  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    redirect: 'follow',
    body: JSON.stringify(body),
  }
  executeFetch(BASE_URLS.faker + url, requestOptions, callback, errorCallback)
}

function executeDelete(url, callback, errorCallback) {
  var myHeaders = new Headers()
  //myHeaders.append('Authorization', 'Bearer ' + token)

  var requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  }

  executeFetch(BASE_URLS.faker + url, requestOptions, callback, errorCallback)
}

const errorCallback = (error) => {
  if (error.message) {
    notify.show(error.message, 'error', 10000)
  } else {
    notify.show(JSON.stringify(error), 'error', 10000)
  }
}

const ServerProvider = ({ children }) => {
  return (
    <Server.Provider
      value={{
        _drones: {
          load: (callback) => executeGet('/simulation', null, callback, errorCallback),
          update: (id, body, callback) => executePut(`/simulation/${id}`, body, callback, errorCallback),
          save: (body, callback) => executePost('/simulation', null, body, callback, errorCallback),
        },
      }}
    >
      {children}
    </Server.Provider>
  )
}

export default ServerProvider
